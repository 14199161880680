<template>
  <div class="box" style="background: #fff;" v-if="myInfo!=null">

    <img src="https://public.yayale.top/zcxc_20221101182830.png" style="width:100%;height: 200px;object-fit: cover;" alt="">
    <div class="myinfo">
      <span style="font-size:20px;margin-right:10px;font-weight: 600;margin-top: -15px;">{{myInfo.name}}</span>
      <img style="width:70px;height:70px;border-radius: 10px;" :src="myInfo.headimgurl" alt=""/>
    </div>
    <div class="myinfo1" @click="addposts">
      <img style="width:23px;height:23px;border-radius: 10px;" :src="'https://public.yayale.top/icon_cearm.png'" alt=""/>
    </div>
    <div class="list-box">
      <div v-for="(item,indexpos) in postsList" :key="item.id">
        <div class="item-top-box">
          <img :src="item.users.headimgurl" style="width:40px;height:40px;border-radius: 5px;" alt="">
          <div style="width: 100%;">
            <div style="margin-left:10px;">
              <div style="color:#485884;font-size:16px;font-weight: 500;">{{item.users.name}}</div>
              <div style="font-size:16px;margin-top:3px;">{{item.content}}</div>
            </div>
            <div style="margin-left:10px;position: relative;margin-top: 10px;;">
                <img style="width:80px;height: 80px;" class="pic-sty" v-for="(ite,indexpic) in formatPicUrl(item.pic)" :key="indexpic" :src="ite" alt="" @click="showPic(ite)">
            </div>
            <div style="margin-left:10px;color:#AFAFAF;font-size: 12px;margin-top: 5px;display: flex;align-items: center;justify-content: space-between;position: relative;user-select:none">
              <div>{{formatDateTime(item.created_at)}}</div>
              <div class="pl" @click="showdzpl(indexpos,item)">
                <span></span>
                <span></span>
              </div>
              <div class="dzpl" :class="[item.isshow?'open':'close']" v-if="item.isshow">
                <div @click="addLikes(item)" v-if="isLikes==null">点赞</div>
                <div @click="delLikes(item)" v-else>取消</div>
                <div class="slines"></div>
                <div @click="showPlPopup(item)">评论</div>
              </div>
            </div>
            <div style="height: 15px;;"></div>
            <div style="margin-left:10px;background: #F7F7F7;padding: 5px 7px;display: flex;color:rgb(72, 88, 132);flex-wrap: wrap;user-select:none" v-if="(item.likes.length!=0)">
              <span><img style="width:12px;margin-top:3px;margin-right: 5px;" src="./like.png" alt="" v-if="item.likes.length!=0"></span>
              <span v-if="item.likes.length!=0">
                <span v-for="(likes,index) in item.likes" :key="likes.id">
                <span style="color:rgb(72, 88, 132);font-size: 14px;font-weight: 500;">
                  {{likes.users.name}}{{ index == item.likes.length - 1 ? '' : ',' }}
                </span>
              </span>
              </span>
              <div class="commslines" v-if="item.comms.length!=0"></div>
              
            </div>
            <div style="margin-left:10px;background: #F7F7F7;padding: 0 7px;display: flex;color:rgb(72, 88, 132);flex-wrap: wrap;user-select:none" v-if="item.comms.length!=0">
                <div v-for="(comms,commsindex) in item.comms" :key="comms.id" style="width:100%;margin-bottom:3px;user-select:none" >
                  <!-- <van-popover
                    v-model="comms.ischeck"
                    trigger="click"
                    :actions="actions"
                    @select="onSelect"
                  >
                    <template #reference>
                      <span style="font-size:14px;font-weight: 500;user-select:none">{{comms.users.name}}：</span>
                    <span style="font-size:14px;color: #000;font-weight: 500;user-select:none">{{comms.content}}</span>
                    </template>
                  </van-popover> -->
                  <div style="line-height: 23px;" @touchstart="showDeleteButton(indexpos,comms,commsindex)" @touchend="clearLoop(item.id)"  class="commshover" @click="commUser(comms)">
                    <span style="font-size:14px;font-weight: 500;user-select:none">{{comms.users.name}}：</span>
                    <span style="font-size:14px;color: #000;font-weight: 500;user-select:none">{{comms.content}}</span>
                  </div>
                  <div v-for="ite in comms.comm_info" :key="ite.id" style="line-height: 23px;" @touchstart="showDeleteButton(indexpos,comms,commsindex)" @touchend="clearLoop(item.id)" class="commshover" @click="commUser(comms,ite)">
                    <span style="font-size:14px;font-weight: 500;user-select:none">{{ite.from_users.name}} 回复 {{ ite.to_users.name }}：</span>
                    <span style="font-size:14px;color: #000;font-weight: 500;user-select:none">{{ ite.commContent }}</span>
                    
                  </div>
                </div>
              </div>
            <div class="lines"></div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showCommsPopup" position="bottom" round>
        <div style="width: 100%;">
          <!-- <div style="text-align:center;margin:15px 0;font-size:16px;">审核</div> -->
          <textarea placeholder="请输入评论" class="texta" v-model="content"></textarea>
          <div class="btn-box">
            <div class="cklx1" @click="sureComms">确定</div>
          </div>
        </div>
    </van-popup>
    <van-popup v-model="showCommsInfoPopup" position="bottom" round>
        <div style="width: 100%;">
          <div style="text-align:left;margin:15px 0;font-size:16px;margin-left: 15px;" v-if="commUserInfo!=null">正在回复：{{ commUserInfo.name }}</div>
          <textarea placeholder="请输入评论" class="texta" v-model="content"></textarea>
          <div class="btn-box">
            <div class="cklx1" @click="sureCommsInfo">确定</div>
          </div>
        </div>
    </van-popup>
  </div>
</template>
<script>
import { ImagePreview,Popup,Toast,Dialog } from "vant";
// import Empty from "./comm/empty.vue";
// import Loading from "./comm/loading.vue";
import { posts } from "@/api";
import dayjs from 'dayjs'
require('dayjs/locale/de')
dayjs().locale('zh-cn').format() // 局部修改语言配置
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import Config from "@/config";
export default {
  components: {
    // Empty: Empty,
    // Loading: Loading,
    // VanTag: Tag,
    // VanImagePreview: ImagePreview,
    VanPopup: Popup,
    // VanPopover: Popover,
  },
  data() {
    return {
      showCommsPopup: false,
      showCommsInfoPopup: false,
      postsList: [],
      content:"",
      myInfo: null,
      postsInfo: null,
      isLikes: null,
      commsInfo: null,
      showPopover: [],
      actions: [{ text: '删除' }],
      commUserInfo: null,
      commId: "",
    };
  },

  mounted() {
    document.title = "我的圈子";
    // window.addEventListener('mousedown',this.handleonmousedown)
    this.myInfo = JSON.parse(localStorage.getItem("user"))
    this.getPassengerTripList();

  },
  methods: {
    commUser(e,k) {
      // console.log(e,'e')
      // console.log(k,'k')
      
      this.commUserInfo = k == undefined ? e.users : k.from_users
      this.commId = k == undefined ? e.id : k.commId
      // console.log(this.commId,'this.commUserInfo')
      this.showCommsInfoPopup = true
      // 被人都是to
    },
    formatDateTime(e) {
      return dayjs(e).fromNow()
    }, 
    addposts() {
      this.$router.push({
        name: 'add-posts'
      })
    },
    onSelect() {},
    showDeleteButton(e,k,l) {
      console.log(e)
      console.log(l)
        if(k.userId == this.myInfo.id) {
          clearTimeout(this.Loop); //再次清空定时器，防止重复注册定时器
          this.Loop = setTimeout(function() {
            this.commsInfo = k
            Dialog.confirm({
            title: '提醒',
            message: '确定删除评论吗？',
          })
            .then(() => {
              // on confirm
              let params = {
                id: k.id
              }
              posts.delComms(params).then(()=>{
                Toast.success('删除成功');
                this.getPassengerTripList()
              })
            })
            .catch(() => {
              // on cancel
            });
          }.bind(this),1000);
        }
    },
    clearLoop() {
        clearTimeout(this.Loop);
    },
    showPlPopup(e) {
      this.postsInfo = e
      this.showCommsPopup = true
    },
    sureComms() {
      if(this.content == "") {
        Toast.fail('请输入评论');
        return
      }
      let params = {
        id: this.postsInfo.id,
        content: this.content
      }
      posts.addComms(params).then(()=>{
        this.showCommsPopup = false
        this.content = ""
        this.getPassengerTripList();
      })
    },
    sureCommsInfo() {
      if(this.content == "") {
        Toast.fail('请输入评论');
        return
      }
      let params = {
        to_user: this.commUserInfo.id,
        comm_id:this.commId,
        content: this.content
      }
      console.log(params,'params')
      posts.addCommsInfo(params).then(()=>{
        this.showCommsInfoPopup = false
        this.content = ""
        this.getPassengerTripList();
      })
    },
    addLikes(e) {
      let params = {
        id: e.id
      }
      posts.addLikes(params).then(()=>{
        this.getPassengerTripList();
      })
    },
    delLikes(e) {
      let params = {
        id: e.id
      }
      posts.delLikes(params).then(()=>{
        this.getPassengerTripList();
      })
    },
    getLikesed(e) {
      let params = {
        id: e.id
      }
      posts.getLikesed(params).then(res=>{
        this.isLikes = res.data.data
      })
    },
    // handleonmousedown(){
    //   for(let i in this.postsList) {
    //     this.postsList[i].isshow = false
    //   }
    // },
    showdzpl(e,k) {
      console.log(e)
      for(let i in this.postsList) {
        this.postsList[i].isshow = false
      }
      this.getLikesed(k)
      this.postsList[e].isshow = true
    },
    showPic(e) {
      console.log(e)
      ImagePreview([e]);
    },
    formatPicUrl(e) {
      let lstring = []
      if(!e) {
        return []
      }
      let ls = e.split(',')
      for(let i in ls) {
        lstring.push(Config.resource.base + ls[i])
      }
      console.log()
      return lstring
    },
    getPassengerTripList() {
      this.isShowLoading = true;
      let params = {
        page: 1,
        pageSize: 15,
        searchkey: '',
      };
      posts.getPostsList(params).then((res) => {
        this.postsList = []
        for(let i in res.data.data) {
          this.postsList.push({
            content: res.data.data[i].content,
            id: res.data.data[i].id,
            pic: res.data.data[i].pic,
            title: res.data.data[i].title,
            userId: res.data.data[i].userId,
            users: res.data.data[i].users,
            likes: res.data.data[i].likes,
            comms: res.data.data[i].comms,
            created_at: res.data.data[i].created_at,
            isshow: false
          })
        }
        this.isShowLoading = false;
      });
    },
  },
};
</script>
<style scoped>
.box{position: relative;}
.box::-webkit-scrollbar{width: 0 !important;}
.list-box {width: 92%;margin-left: 4%;margin-top: 45px;}
.item-top-box {display: flex; align-items: flex-start;}
.pic-sty {object-fit: cover;margin-right: 5px;border-radius: 5px;}
.lines {height: 1px;width: 100%;background: #f2f2f2;margin: 10px 0 15px 0;}
.commslines{height: 1px;width: 100%;background: #F0F0F0;margin: 4px 0 0px 0;}
.myinfo {position: absolute;display: flex;align-items: center;top: 155px;right: 15px;color:#fff;}
.myinfo1 {position: absolute;display: flex;align-items: center;top: 15px;right: 15px;color:#fff;}
.pl{height: 19px;width: 29px;background: #F7F7F7;border-radius: 3px;display: flex;align-items: center;justify-content:center;}
.pl span{height: 3px;width: 3px;background: #4F5F89;border-radius: 50%;margin: 0 2px;}
.dzpl {width:90px;height:17px;position: absolute;display: flex;top: -6px;right: 35px;background: #000;padding: 7px 15px;border-radius: 5px;align-items: center;justify-content: space-around;}
.dzpl div{color: #fff;}
.dzpl div:nth-child(1){width: 35px;text-align: center;white-space: nowrap;}
.dzpl div:nth-child(3){width: 35px;text-align: center;white-space: nowrap;}
.slines{width: 1px;height: 15px;background: #fff;margin: 0 15px;}
.close {animation: right 0.1s linear both;}
.open {animation: left 0.1s linear both;}
.btn-box{width: 90%;margin-left:5%; display: flex;align-items: center;justify-content: space-between;}
.texta {width: 85%;margin-left: 5%;font-size: 16px;height: 120px;padding: 10px;border: 1px solid #f2f2f2;border-radius: 10px;resize: none;margin-top: 5px;}
.cklx1 {width: 100%;height: 45px;color:#fff;background: #2d83e8;border-radius: 20px;font-size: 18px;line-height: 45px;text-align: center;margin-top: 20px;margin-bottom: 20px;
}
.commshover:active {
  background: rgb(175, 175, 175);
  border-radius: 3px;;
}
@keyframes right {
        from {
            width: 90px;
        }
 
        to {
            width: 0px;
        }
    }
@keyframes left {
        from {
            width: 0px;
        }
 
        to {
            width: 90px;
        }
    }
</style>
